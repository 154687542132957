import http from './http'

class ApiKeys {
  list() {
    return http.get(`/cedente/api_keys`)
  }
  
  create() {
    return http.post(`/cedente/api_keys`, {})
  }
  
  disable(id) {
    return http.post(`/cedente/api_keys/${id}/disable`, {})
  }
}

export default new ApiKeys()
